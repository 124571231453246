import ConfigurableColumnBuilder from "common/agGrid/configurableColumnBuilder";
import { formatDecimalPlace, formatOrderDisplayId, formatPricingValue } from "common/formatters";
import { getProducerDisplayName, Producer } from "features/settings/producers/producer";
import { ColumnCustomization } from "common/agGrid/columnCustomization";
import { getDeliveryMethodText } from "domain/deliveryMethod";
import Contract, { getContractTypeName } from "domain/contract";
import React, { useEffect, useState } from "react";
import SiteLink from "common/ui/SiteLink";
import { getDeliveryPeriodDisplayName } from "domain/deliveryPeriod";
import { compareDisplayIds } from "common/strings";
import { getPaymentOptionName } from "domain/paymentOption";
import usePermission from "common/security/usePermission";
import Permissions from "common/security/permissions";
import { canShowOrderId } from "features/offers/orderStatus";
import { Form } from "react-bootstrap";
import useUniqueId from "common/ui/useUniqueId";
import { getTotalFees } from "./helpers"
import { getProducerAccountDisplayName } from "features/settings/producers/producerAccount";
import contractTypeHelper from "domain/contractTypeHelper";
import { UnitConversionRate } from "domain/unit";
import unitHelper from "domain/unitHelper";

export class ContractColumn {
    //These strings should never change after publishing.
    //Otherwise it will invalidate user settings
    public static id: string = "id";
    public static flag: string = "flag";
    public static customer: string = "customer";
    public static customerAccount: string = "customerAccount";
    public static commodity: string = "commodity";
    public static position: string = "Position";
    public static location: string = "location";
    public static contractLocation: string = "contractLocation";
    public static customerWillSell: string = "customerWillSell";
    public static quantity: string = "quantity";
    public static cashPrice: string = "cashPrice";
    public static finalPrice: string = "finalPrice";
    public static basis: string = "basis";
    public static contractSymbol: string = "contractSymbol";
    public static deliveryEnd: string = "deliveryEnd";
    public static trader: string = "trader";
    public static lastUpdatedDate: string = "lastUpdatedDate";
    public static lastUpdatedBy: string = "lastUpdatedBy";
    public static deliveryPeriod: string = "deliveryPeriod";
    public static totalFee: string = "totalFee";
    public static deliveryStart: string = "deliveryStart";
    public static createdDate: string = "createdDate";
    public static createdBy: string = "createdBy";
    public static contractExpiration: string = "contractExpiration";
    public static comments: string = "comments";
    public static deliveryMethod: string = "deliveryMethod";
    public static freightFee: string = "freightFee";
    public static workingQuantity: string = "workingQuantity";
    public static remainingQuantity: string = "remainingQuantity";
    public static erpId: string = "erpId";
    public static orderId: string = "orderId";
    public static fillPrice: string = "fillPrice";
    public static futuresPrice: string = "futuresPrice";
    public static actualBasis: string = "actualBasis";
    public static myBasis: string = "myBasis";
    public static fillTime: string = "fillTime";
    public static producerAccount: string = "producerAccount";
    public static paymentOption: string = "paymentOption";
    public static contractType: string = "contractType";
    public static currency: string = "currency";

    /* Mobile-specific columns */
    public static remainingQuantity_mobile: string = "remainingQuantity_mobile";
}

export function getColumnBuilder(isCustomerEditAllowed: boolean, 
    onCustomerSelected: any, 
    isPaymentOptionHidden: boolean, 
    isCustomer: boolean,
    isMobile: boolean,
    displayContractLocation: boolean,
    conversionRates: UnitConversionRate[],
    onContractFlagUpdated?: any,
    onErpIdSelected?: any,
    showQuantityUnits?: boolean,
    showLocalUnits?: boolean): ConfigurableColumnBuilder {


    const builder = new ConfigurableColumnBuilder();
    const defaultClass = isMobile ? "mobile": "";

    //Default columns
    builder.for(ContractColumn.id, "ID#", "displayId", "Contract ID")
        .withCellRenderer<Contract>(params => {
            if (params.node.data == null) return null;
            
            return <SiteLink.ContractDetails contractDisplayId={params.node.data.displayId} />
        })
        .withComparator(compareDisplayIds)
        .withColumnClass(defaultClass, defaultClass)
        .withWidth(isMobile ? 55: 95);
    builder.for(ContractColumn.flag, "Flag", "flag", "Flag", { hide: isCustomer, width: 30 })
        .withCellRenderer<Contract>(params => params.node.group ? null : <FlagCheckbox
            isChecked={params.value}
            onChange={isChecked => onContractFlagUpdated({ ...params.node.data, flag: isChecked })}
            />),
    builder.for(ContractColumn.customer, "Customer", "", "", { hide: isCustomer })
        .withGetter(params => getProducerDisplayName(params.data?.producer))
        .withCellRenderer<Contract>(params => {
            const producer: Producer = params.node.data?.producer;
            
            if(!isCustomerEditAllowed)
                return <span>{getProducerDisplayName(producer)}</span>

            else if(!producer.isActive)
                return <span style={{color: "gray" }}>{getProducerDisplayName(producer)}</span>

            return <button className="link-button" onClick={() => onCustomerSelected(producer)} >
                {getProducerDisplayName(producer)}
            </button>
        })
        .withWidth(150);

    builder.for(ContractColumn.commodity, "Commodity", "commodity.name")
        .withWidth(100);
    builder.for(ContractColumn.position, "Position", "positionSetting.displayName", "Position", { hide: isCustomer})
        .withWidth(80);
    builder.for(ContractColumn.location, "Location", "location.name", "Inventory Location")
        .withFormatter(m => m ?? "Determine Later")
        .withWidth(150);
    builder.for(ContractColumn.contractLocation, "Cont Loc", "contractLocation.name", "Contract Location", { hide: !displayContractLocation })
        .withFormatter(m => m ?? "Determine Later")
        .withWidth(150);
    builder.for(ContractColumn.customerWillSell, "B/S", "customerWillSell", "Buy or Sell")
        .withFormatter(val => val ? "Sell" : "Buy")
        .withWidth(60);
    if(showLocalUnits)
    {
        builder.for(ContractColumn.quantity, "QTY", "quantityContracted", "Contract Quantity", {aggFunc: "npSum"})
            .asNumber()
            .withGetter(params => !showQuantityUnits ? params.data?.quantityContracted : unitHelper.getQuantityTextWithUnits(params.data?.quantityContracted, params.data?.commodity, conversionRates))
            .withTooltipValueGetter(params => !showQuantityUnits && params.data != null ? unitHelper.getLocalQuantityTextWithUnits(params.data.quantityContracted, params.data.commodity, conversionRates) : null)
            .withColumnClass(defaultClass, defaultClass)
            .withWidth(isMobile ? 50: 120);
        builder.for(ContractColumn.cashPrice, "Cash", "cashPrice", "Contract Cash Price")
            .withGetter(params => unitHelper.getLocalPriceText(params.data?.cashPrice, params.data?.commodity, conversionRates))
            .asRightAligned()
            .withColumnClass(defaultClass, defaultClass)
            .withWidth(isMobile ? 55: 120);
        builder.for(ContractColumn.finalPrice, "Final Price", "finalPrice", "Contract Final Price")
            .withGetter(params => unitHelper.getLocalPriceText(params.data?.finalPrice, params.data?.commodity, conversionRates))
            .asRightAligned()
            .withColumnClass(defaultClass, defaultClass)
            .withWidth(isMobile ? 55: 80);
        builder.for(ContractColumn.totalFee, "Fee", "", "Total Fees")
            .withGetter(params => getTotalFees(params.node.data))
            .withTooltipValueGetter(params => params.data != null ? unitHelper.getLocalPrice(getTotalFees(params.data), params.data.commodity, conversionRates) : null)
            .asDecimal()
            .asRightAligned()
            .withWidth(60);
    }
    else {
        builder.for(ContractColumn.quantity, "QTY", "quantityContracted", "Contract Quantity", {aggFunc: "npSum"})
            .asNumber()
            .withColumnClass(defaultClass, defaultClass)
            .withWidth(isMobile ? 50: 60);
        builder.for(ContractColumn.cashPrice, "Cash", "cashPrice", "Contract Cash Price")
            .withFormatter(formatPricingValue)
            .asRightAligned()
            .withColumnClass(defaultClass, defaultClass)
            .withWidth(isMobile ? 55: 80);
        builder.for(ContractColumn.finalPrice, "Final Price", "finalPrice", "Contract Final Price")
            .withFormatter(formatPricingValue)
            .asRightAligned()
            .withColumnClass(defaultClass, defaultClass)
            .withWidth(isMobile ? 55: 80);
        builder.for(ContractColumn.totalFee, "Fee", "", "Total Fees")
            .withGetter(params => getTotalFees(params.node.data))
            .asDecimal()
            .asRightAligned()
            .withWidth(60);
    }
    builder.for(ContractColumn.workingQuantity, "Working QTY", "workingQuantity", "Working Quantity", {aggFunc: "npSum"})
        .asNumber()
        .withTooltipValueGetter(params => showLocalUnits && params.data != null ? unitHelper.getLocalQuantityTextWithUnits(params.data.workingQuantity, params.data.commodity, conversionRates) : null)
        .withWidth(120);
        
    if (isMobile)
    {
        builder.for(ContractColumn.remainingQuantity_mobile, "Unpriced", "remainingQuantity", "Unpriced Quantity")
            .asNumber()
            .withColumnClass(defaultClass, defaultClass)
            .withWidth(50);
    }
    else {
        builder.for(ContractColumn.remainingQuantity, "Unpriced QTY", "remainingQuantity", "Unpriced Quantity", {aggFunc: "npSum"})
            .asNumber()
            .withTooltipValueGetter(params => showLocalUnits && params.data != null ? unitHelper.getLocalQuantityTextWithUnits(params.data.remainingQuantity, params.data.commodity, conversionRates) : null)
            .withWidth(120);
    }

   
    builder.for(ContractColumn.currency, "Currency", "contractCurrency.code", "Currency")
        .asRightAligned()
        .withWidth(isMobile ? 55 : 80);
    builder.for(ContractColumn.basis, "Basis", "basis")
        .withFormatter(formatPricingValue)
        .asRightAligned()
        .withColumnClass(defaultClass, defaultClass)
        .withWidth(isMobile ? 50: 60);
    builder.for(ContractColumn.contractSymbol, "Sym", "deliveryPeriod.contract.symbol", "Futures Symbol")
        .withColumnClass(defaultClass, defaultClass)
        .withWidth(50);
    builder.for(ContractColumn.deliveryPeriod, "Del Period", "deliveryPeriod.name", "Delivery Period")
        .withGetter(params => getDeliveryPeriodDisplayName(params.data?.deliveryPeriod))
        .withWidth(195);
    builder.for(ContractColumn.deliveryStart, "Del Start", "deliveryPeriodStartDate", "Delivery Start")
        .asUtcDate()
        .withWidth(90);
    builder.for(ContractColumn.deliveryEnd, "Del End", "deliveryPeriodEndDate", "Delivery End")
        .asUtcDate()
        .withWidth(90);
    builder.for(ContractColumn.trader, "Trader", "trader.lastName")
        .withWidth(100);

    builder.for(ContractColumn.createdBy, "Created By", "createdBy.name", "Created By")
        .withWidth(150);    
    builder.for(ContractColumn.createdDate, "Created Date", "createdDate", "Created Date")
        .asDateTime()
        .withWidth(150);
    builder.for(ContractColumn.lastUpdatedBy, "Modified By", "lastUpdatedBy.name", "")
    builder.for(ContractColumn.lastUpdatedDate, "Last Update", "lastUpdatedDate", "Time Stamp")
        .asDateTime()
        .withWidth(150);
    builder.for(ContractColumn.contractExpiration, "Expiration", "expirationDate", "Expiry of Contract")
        .asUtcDate()
        .withWidth(70);
    builder.for(ContractColumn.comments, "Cmnt", "comments", "Comment")
        .withWidth(150);
    builder.for(ContractColumn.deliveryMethod, "Del Method", "deliveryMethod", "Delivery Method")
        .withGetter(params => getDeliveryMethodText(params.data?.deliveryMethod))
        .withWidth(90);
    builder.for(ContractColumn.freightFee, "Freight Fee", "freightFee", "Freight Fee")
        .withFormatter(formatPricingValue)
        .asRightAligned()
        .withWidth(80);
    builder.for(ContractColumn.fillTime, "Fill T", "offer.fillTime", "Offer Fill Time", { hide: isCustomer})
        .asDateTime()
        .withWidth(150);
    builder.for(ContractColumn.futuresPrice, "Futures", "futuresPrice", "Req. Futures Price")
        .withFormatter(formatPricingValue)
        .withColumnClass(defaultClass, defaultClass)
        .withWidth(isMobile ? 60: 80);
    builder.for(ContractColumn.actualBasis, "Act. Basis", "basis", "Actual Basis")
        .asRightAligned()
        .withCellRenderer<Contract>(params => {
            if (params.node.data == null) return null;
            return params.value 
                ? <span>{params.node.data.offer.isBasisFixed && <i className="material-icons lock-icon">lock</i>}{formatDecimalPlace(params.value)}</span>
                : "--";
        })
        .withWidth(80);
    builder.for(ContractColumn.myBasis, "P. Basis", null, "Posted Basis")
        .withGetter(params => contractTypeHelper.isHta(params.node.data.contractType) ? null : params.node.data.offer?.currentBasis)
        .withFormatter(m => m == null ? "--" : formatDecimalPlace(m))
        .asRightAligned()
        .withWidth(100);
    builder.for(ContractColumn.orderId, "Order#", "offer.order.displayId", "Hedge Order ID#", { hide: isCustomer})
        .withGetter(params => canShowOrderId(params.data?.offer?.order?.status) ? params.data?.offer?.order?.displayId : "")
        .withFormatter(formatOrderDisplayId)  
        .withTooltipValueGetter(params => canShowOrderId(params.data?.offer?.order?.status) ? params.data?.offer?.order?.displayId : "")  
        .withWidth(90);
    builder.for(ContractColumn.erpId, "ERP ID#", "erpDisplayId", "ERP ID#")
        .withCellRenderer<Contract>(params => <ErpId value={params.value} contract={params.node.data} onSelected={() => onErpIdSelected(params.node.data)} />)
        .withWidth(90);
    builder.for(ContractColumn.fillPrice, "Fill P", "offer.order.fillPrice", "Order Fill Price", { hide: isCustomer})
        .asDecimal()
        .withWidth(80);
    builder.for(ContractColumn.producerAccount, "Acct ID", "", "External Acct ID")
        .withGetter(params => getProducerAccountDisplayName(params.data?.customerAccount))
        .withWidth(160);
    if(!isPaymentOptionHidden){
        builder.for(ContractColumn.paymentOption, "Payment Option", "paymentOption", "Payment Option")
            .withFormatter(getPaymentOptionName)
            .withWidth(120);
    }
    builder.for(ContractColumn.contractType, "Type", "contractType", "Contract Type")
        .withFormatter(getContractTypeName)
        .withWidth(100);
    return builder;
}

interface ErpIdProps {
    value: string;
    contract: Contract;
    onSelected(): void;
}

const ErpId: React.FC<ErpIdProps> = props => {
    
    const canViewSyncErrors = usePermission(Permissions.ManageErpMapping);

    if (props.contract == null) return null;

    if(canViewSyncErrors && !props.contract.isErpInSync) {
        return <button className="link-button" onClick={props.onSelected} >
            {props.value !== "Error" ? props.value : null}
            <i className="pl-1 text-danger fa fa-exclamation-triangle"></i>
        </button>
    }
    else {
        return <span>{props.value}</span>
    }
}

interface FlagCheckboxProps {
    isChecked: boolean;
    onChange(isChecked: boolean): void;
}

const FlagCheckbox: React.FC<FlagCheckboxProps> = props => {

    const [isChecked, setIsChecked] = useState(props.isChecked);
    const id = useUniqueId();

    useEffect(() => {
        setIsChecked(props.isChecked);
    }, [props.isChecked]);

    function onChange(newVal: boolean) {
        setIsChecked(newVal);
        props.onChange(newVal);
    }

    return <Form.Check  
        id={id} 
        custom
        label=""
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
        checked={isChecked} />;
}

export interface ContractColumnConfigurations {
    basis: ColumnCustomization;
    cash: ColumnCustomization;
    hta: ColumnCustomization;
    delayedPrice: ColumnCustomization;
    withOptions: ColumnCustomization;
}

export const defaultContractColumns: ContractColumnConfigurations = {
    basis: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            ContractColumn.id,
            ContractColumn.flag,
            ContractColumn.erpId,
            ContractColumn.customer,
            ContractColumn.producerAccount,
            ContractColumn.commodity,
            ContractColumn.position,
            ContractColumn.location,
            ContractColumn.contractLocation,
            ContractColumn.customerWillSell,
            ContractColumn.quantity,
            ContractColumn.workingQuantity,
            ContractColumn.remainingQuantity,
            ContractColumn.basis,
            ContractColumn.contractSymbol,
            ContractColumn.deliveryPeriod,
            ContractColumn.trader,
            ContractColumn.lastUpdatedDate
    ]},
    cash: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            ContractColumn.id,
            ContractColumn.flag,
            ContractColumn.erpId,
            ContractColumn.orderId,
            ContractColumn.customer,
            ContractColumn.producerAccount,
            ContractColumn.commodity,
            ContractColumn.position,
            ContractColumn.location,
            ContractColumn.contractLocation,
            ContractColumn.customerWillSell,
            ContractColumn.quantity,
            ContractColumn.cashPrice,
            ContractColumn.futuresPrice,
            ContractColumn.currency,
            ContractColumn.finalPrice,
            ContractColumn.fillPrice,
            ContractColumn.actualBasis,
            ContractColumn.myBasis,
            ContractColumn.contractSymbol,
            ContractColumn.deliveryPeriod,
            ContractColumn.trader,
            ContractColumn.fillTime,
            ContractColumn.lastUpdatedDate
    ]},
    hta: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            ContractColumn.id,
            ContractColumn.flag,
            ContractColumn.erpId,
            ContractColumn.orderId,
            ContractColumn.customer,
            ContractColumn.producerAccount,
            ContractColumn.commodity,
            ContractColumn.position,
            ContractColumn.location,
            ContractColumn.contractLocation,
            ContractColumn.customerWillSell,
            ContractColumn.quantity,
            ContractColumn.remainingQuantity,
            ContractColumn.futuresPrice,
            ContractColumn.currency,
            ContractColumn.finalPrice,
            ContractColumn.fillPrice,
            ContractColumn.contractSymbol,
            ContractColumn.deliveryPeriod,
            ContractColumn.totalFee,
            ContractColumn.trader,
            ContractColumn.fillTime,
            ContractColumn.lastUpdatedDate
    ]},
    delayedPrice: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            ContractColumn.id,
            ContractColumn.flag,
            ContractColumn.erpId,
            ContractColumn.customer,
            ContractColumn.producerAccount,
            ContractColumn.commodity,
            ContractColumn.location,
            ContractColumn.contractLocation,
            ContractColumn.customerWillSell,
            ContractColumn.quantity,
            ContractColumn.workingQuantity,
            ContractColumn.remainingQuantity,
            ContractColumn.trader,
            ContractColumn.contractExpiration,
            ContractColumn.createdDate,
            ContractColumn.lastUpdatedDate
        ]
    },
    withOptions: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            ContractColumn.id,
            ContractColumn.flag,
            ContractColumn.contractType,
            ContractColumn.erpId,
            ContractColumn.orderId,
            ContractColumn.customer,
            ContractColumn.producerAccount,
            ContractColumn.commodity,
            ContractColumn.position,
            ContractColumn.location,
            ContractColumn.contractLocation,
            ContractColumn.customerWillSell,
            ContractColumn.quantity,
            ContractColumn.cashPrice,
            ContractColumn.futuresPrice,
            ContractColumn.finalPrice,
            ContractColumn.totalFee,
            ContractColumn.fillPrice,
            ContractColumn.actualBasis,
            ContractColumn.myBasis,
            ContractColumn.contractSymbol,
            ContractColumn.deliveryPeriod,
            ContractColumn.trader,
            ContractColumn.fillTime,
            ContractColumn.lastUpdatedDate
    ]}
}


export const mobileColumns: ContractColumnConfigurations = {
    basis: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            ContractColumn.id,
            ContractColumn.customer,
            ContractColumn.quantity,
            ContractColumn.remainingQuantity_mobile,
            ContractColumn.basis,
            ContractColumn.contractSymbol
    ]},
    cash: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            ContractColumn.id,
            ContractColumn.customer,
            ContractColumn.quantity,
            ContractColumn.cashPrice,
            ContractColumn.contractSymbol
    ]},
    hta: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            ContractColumn.id,
            ContractColumn.customer,
            ContractColumn.quantity,
            ContractColumn.remainingQuantity_mobile,
            ContractColumn.futuresPrice,
            ContractColumn.contractSymbol
    ]},
    delayedPrice: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            ContractColumn.id,
            ContractColumn.customer,
            ContractColumn.quantity,
            ContractColumn.remainingQuantity_mobile
        ]
    },
    withOptions: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            ContractColumn.id,
            ContractColumn.customer,
            ContractColumn.quantity,
            ContractColumn.cashPrice,
            ContractColumn.contractSymbol
    ]}
}